<template>
    <v-app>
        <v-navigation-drawer v-model="drawer"
                             app
                             fixed
                             temporary>
            <v-list nav dense>
                <v-list-item-group v-model="group"
                                   active-class="blue--text text--accent-4">
                    <v-list-item v-show="canAcknowledgeCriticals" to="/results">
                        <v-list-item-icon>
                            <v-icon>mdi-home</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Results</v-list-item-title>
                    </v-list-item>
                    <v-list-item  v-show="canEditCollectionDate"  to="/scantubes">
                        <v-list-item-icon>
                            <v-icon>mdi-information</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Scan Tubes</v-list-item-title>
                    </v-list-item>
                    <v-list-item to="/about">
                        <v-list-item-icon>
                            <v-icon>mdi-information</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Getting Started</v-list-item-title>
                    </v-list-item>
                    <v-list-item to="/contact">
                        <v-list-item-icon>
                            <v-icon>mdi-phone-classic</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Contact</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="authenticated" to="/login">
                        <v-list-item-icon>
                            <v-icon>mdi-logout</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Log Out</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-else to="/login">
                        <v-list-item-icon>
                            <v-icon>mdi-login</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Log In</v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
        <v-navigation-drawer v-model="settings" 
                             app
                             fixed
                             right
                             temporary>
            <results-settings v-show="currentRoute === 'results'" :visible="settings"></results-settings>
        </v-navigation-drawer>
        <v-app-bar app fixed>
            <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
            <v-toolbar-title style="padding-left:0px;padding-top:10px"><img src="@/assets/main-logo.png" height="60" /></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn v-if="authenticated"  @click="settings = true" icon>
                <v-icon>mdi-cog-outline</v-icon>
            </v-btn>
        </v-app-bar>
        <v-main>
            <!-- Provides the application the proper gutter -->
            <v-container fluid>
                <!-- If using vue-router -->
                <router-view></router-view>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
    import ResultsSettings from "@/components/ResultsSettings";    

    export default {
        name: "App",
        components: { ResultsSettings },
        data: () => ({
            drawer: false,
            settings: false,
            group: null
        }),
        computed: {
            authenticated: {
                get() {
                    return this.$store.state.authenticated;
                }
            },
            canAcknowledgeCriticals: {
                get() {
                    
                    return this.$store.state.canAcknowledgeCriticals && this.$store.state.authenticated;
                }
            },
            canEditCollectionDate: {
                get() {
                   
                    return this.$store.state.canEditCollectionDate && this.$store.state.authenticated;
                }
            },
            currentRoute: {
                get() {
                    if (this.$route.path == "/results") {
                        return "results";
                    } else if (this.$route.path == "/scantubes") {
                        return "scantubes"
                    }
                    return "other";
                }
            }            
        }
    };
</script>