export default {
    getLabelData: function (barcode) {
        
        return fetch("/api/getlabeldata?barcode=" + barcode, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            } 
        }); 
    },
    getscansettings: function () {

        return fetch("/api/GetScanSettings", {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });
    },
    updateActualCollectionDate: function (patientOrderId, patientId, actualCollectionDate) {
        return fetch("/api/updateactualcollectiondate",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ patientOrderId, patientId, actualCollectionDate })
            });
    }
};
