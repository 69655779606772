import Vue from "vue";
import Vuex from "vuex";
import resultStore from "./resultStore";
import facilityStore from "./facilityStore";
    
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
        authenticated: false,
        canAcknowledgeCriticals: false,
        canEditCollectionDate: false
  },
  mutations: {
      logIn(state, { canAcknowledgeCriticals, canEditCollectionDate }) {
          state.authenticated = true;
          state.canAcknowledgeCriticals = canAcknowledgeCriticals;
          state.canEditCollectionDate = canEditCollectionDate;
      },
      logOut(state) {
          state.authenticated = false;
          state.canAcknowledgeCriticals = false;
          state.canEditCollectionDate = false;
      }
  },
  actions: {},
  modules: {
      resultStore,
      facilityStore
  }
});
