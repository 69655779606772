

<template>

    <v-card class="mx-auto"
            max-width="344">
        <div id="divScanner" v-show="showScanner &&  !showLoading">

            <v-card-title>Scan Tubes  </v-card-title>

            <v-scandit v-if="scanninglicencekey.length" :license-key="scanninglicencekey"
                       :configuration-options="{ engineLocation: 'https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build/' }"
                       :scan-settings="{ enabledSymbologies: ['ean8', 'ean13', 'upca', 'upce','code128'] }"
                       v-on:scan="onScan" />


            <v-spacer></v-spacer>
            <br />

            <v-text-field type="text" v-model="scannedbarcode" label="Enter Barcode if unable to scan" />
            <v-spacer></v-spacer>
            <v-btn @click="getBarcodeData" color="primary" class="btn btn-block">GO</v-btn>
        </div>
        <v-flex xs10 class="mx-auto">
            <v-progress-circular model-value="20" v-show="showLoading">

            </v-progress-circular>
        </v-flex>
        <v-container v-show="!showScanner  && !showLoading && !(labeldata.patientId > 0)">
            <v-card>
                <v-alert style="color:red;">
                    {{labeldata.reason}}
                </v-alert>
            </v-card>
            <v-btn @click="scanMore" class="btn btn-block">Scan more</v-btn>
        </v-container>
        <v-container id="labelData" v-show="!showScanner  && !showLoading && labeldata.patientId > 0">
            <v-card-title>{{labeldata.patientName}} </v-card-title>
            <v-card-subtitle>
                <div class="subtitlecontainer"> <div>DOB : <b>{{formatDate(labeldata.dateOfBirth)}}</b></div><div style="text-align:right;"> MRN : <b>{{labeldata.labCheckPatientMrn}}</b></div></div>

                {{labeldata.facilityCd}} - {{labeldata.facilityName}}
                <br />
                <div class="subtitlecontainer">
                    <div>
                        Printed : <b>{{formatDate(labeldata.scheduledCollectionDate)}}</b>
                    </div>
                    <div>
                        Actual : <b>{{formatDate(labeldata.acutalCollectionDate)}}</b>
                    </div>
                </div>
            </v-card-subtitle>
            <v-card-text>
                <v-card>
                    <v-alert style="color:red;" v-show="!labeldata.isCollectionDateEditable">
                        {{labeldata.reason}}
                    </v-alert>
                </v-card>

                <v-card id="DateContainer" v-show="labeldata.isCollectionDateEditable">
                    <v-card-text>
                        <label v-show="!toggleDateListContainer"> <b>Set Actual Collection date</b></label>
                        <div class="dateContainer" v-show="!toggleDateListContainer">
                            <div class="column">
                                <v-btn v-for="(item) in oddDates" :key="item" @click="setActucalCollectionDate(item)" color="primary" class="btn btn-block">{{item}}</v-btn>
                            </div>
                            <div class="column">
                                <v-btn v-for="(item) in evenDates" :key="item" @click="setActucalCollectionDate(item)" color="primary" class="btn btn-block">{{item}}</v-btn>
                            </div>
                        </div>

                        <v-alert style="color:green;" v-show="toggleDateListContainer">Sucessfully set collection date to {{formatDate(newActualCollectionDate)}}</v-alert>

                    </v-card-text>
                </v-card>
            </v-card-text>

            <v-card-actions>

                <v-btn v-show="toggleDateListContainer" @click="scanMore" class="btn btn-block">Scan more</v-btn>
                <v-btn v-show="!toggleDateListContainer" @click="scanMore" class="btn btn-block">Cancel</v-btn>
            </v-card-actions>
            <v-card-text>
                <v-card>
                    <v-card-text>
                        <label  > <b>Order detail:</b></label>

                        <div v-for="(label) in labeldata.labels" :key="label.orderNumber">
                            <div class="imagecontainer" :style="{
                             backgroundImage: 'url(' +'/assets/'+ label.tubeImageFileName + ')'}">
                                <div style="font-weight:bolder;">
                                    {{ label.barCode }}
                                </div>
                            </div>
                            <div>
                                <label v-for="testcode in csvToList(label.testList)" :key="testcode">
                                    {{testcode}}
                                </label>
                            </div>
                            <v-divider class="mx-4 mb-1"></v-divider>
                        </div>

                    </v-card-text>
                </v-card>
            </v-card-text>
        </v-container>
    </v-card>


</template>

<script>
    import labelService from "@/services/labelService";
    import moment from 'moment'

    export default {
        created() {
            labelService
                .getscansettings()
                .then(response => response.json())
                .then(data => {
                    this.scanninglicencekey = data.currentValue.licenseKey;
                });
        },

        name: "ScanTubes",
        data: function () {
            return {
                scannedbarcode: '',
                showScanner: true,
                showLoading: false,
                dataSuccessfullySaved: false,
                toggleDateListContainer: false,
                newActualCollectionDate: '',
                dateList: [],
                scanninglicencekey: "",
                labeldata: {
                    patientName: '',
                    patientId: '',
                    scheduledCollectionDate: '',
                    acutalCollectionDate: '',
                    patientOrderId: '',
                    sex: '',
                    dateOfBirth: '',
                    labCheckPatientMrn: '',
                    facilityCd: '',
                    facilityName: '',
                    labels: [],
                    orderStatusCdId: '',
                    isCollectionDateEditable: false,
                    reason: ''
                }
            };
        },

        computed: {
            oddDates() {
                return this.dateList.filter((_, index) => index % 2 === 0);
            },
            evenDates() {
                return this.dateList.filter((_, index) => index % 2 !== 0);
            },
            reason() {
                return this.dataList.reason;
            }

        },
        methods: {
            onScan(barcode) {
                this.scannedbarcode = barcode.barcodes[0].data;

                this.getBarcodeData();
            },
            onSubmit(e) {
                e.preventDefault();
                this.getBarcodeData();
            },
            getBarcodeData() {
                if (this.showLoading || !(this.showScanner)) {
                    return;
                }
                this.showLoading = true;

                if (!this.scannedbarcode)
                    alert('No barcode selected.')
                labelService
                    .getLabelData(this.scannedbarcode)
                    .then(response => response.json())
                    .then(data => {
                        this.labeldata = data;
                        this.showScanner = false;

                        this.showLoading = false;
                        this.toggleDateListContainer = false;
                        this.dateList = [];
                        for (let i = 0; i < 7; i++) {
                            var date = moment().subtract(i, 'days');
                            if (date > moment() || date > moment(this.labeldata.scheduledCollectionDate).add(7, 'days')) {
                                continue;
                            }
                            this.dateList.push(date.format("MM/DD/YYYY"));
                        }

                    })
                    .catch(() => {
                        this.showScanner = true;
                        this.showLoading = false;
                        alert('Tube not found.');
                    });




            },
            setActucalCollectionDate(actualDate) {

                this.showLoading = true;
                this.dataSuccessfullySaved = false;
                this.newActualCollectionDate = actualDate;
                labelService
                    .updateActualCollectionDate(this.labeldata.patientOrderId, this.labeldata.patientId, actualDate)
                    .then(response => response.json())
                    .then(data => {

                        if (data == "SUCCESS") {
                            this.toggleDateListContainer = true;

                        } else {
                            alert('Error setting Actual Collection date. Error:' + data);
                        }
                        this.dataSuccessfullySaved = true;
                        this.showLoading = false;
                    })
                    .catch(() => {
                        alert('Error setting Actual Collection date');
                    });
            },
            scanMore() {
                this.showScanner = true;
                this.scannedbarcode = '';
            },
            showBarcodeInputForm() {
                this.showScanner = false;
                this.scannedbarcode = '';
            },
            formatDate(value) {

                return moment(value).format("MM/DD/YYYY");
            },
            csvToList(csvstring) {
                return (csvstring != null) ? csvstring.split(',') : '';
            }


        }
    }

</script>
<style scoped>
    .v-progress-circular {
        display: block;
        width: 100px;
        margin: 0 auto;
    }

    .column {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    button {
        padding: 5px 10px;
        font-size: small;
    }

    .dateContainer, .subtitlecontainer {
        display: flex;
        gap: 10px;
        font-size: small;
    }

    .imagecontainer {
        height: 50px;
        width: 250px;
        text-align: center;
        background-size: contain;
        box-sizing: border-box;
        display: block;
        border: 1px thin;
        top: 50%;
        left: 50%;
        padding: 10px;
    }
</style>